import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import { Position } from '~/types';
import Spinner from '~/components/Spinner';

import styles from './Button.module.scss';

type IconProps = {
  hasError?: boolean;
  iconClass?: string;
  icon?: string | React.ReactElement<{}>;
  iconPosition?: Position;
};

type StyleProps = {
  isSmall?: boolean;
  isFluid?: boolean;
  isTransparent?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isSecondary?: boolean;
  isSpecialWhite?: boolean;
  isGray?: boolean;
  withoutBorder?: boolean;
  isText?: boolean;
};

type a = {
  tag: 'a';
  href: string;
  target?: '_blank';
  rel?: 'noopener noreferrer';
};

type button = {
  tag: 'button';
  type?: 'button' | 'submit' | 'reset';
  name?: string;
};

type link = {
  tag: 'Link';
  to: string;
};

type Props = {
  className?: string;
  as?: a | button | link;
  onClick?: () => void;
} & IconProps &
  StyleProps;

const DEFAULT_ERROR_MESSAGE = 'Try again';

const getIcon: React.FC<IconProps> = (props) => {
  if (props.hasError) {
    return <span className={styles.icon}>Error</span>;
  } else if (props.icon) {
    const Icon = props.icon;
    const classes = classNames(styles.icon, props.iconClass);
    return (
      <span className={classes}>
        {typeof Icon !== 'string' ? (
          Icon
        ) : (
          <img src={Icon} alt='' role='presentation' />
        )}
      </span>
    );
  }

  return null;
};

const Button: React.FC<Props> = (props) => {
  const classes = classNames(styles.root, props.className, {
    [styles.small]: props.isSmall,
    [styles.fluid]: props.isFluid,
    [styles.transparent]: props.isTransparent,
    [styles.disabled]: props.isDisabled,
    [styles.loading]: props.isLoading,
    [styles.secondary]: props.isSecondary,
    [styles.specialWhite]: props.isSpecialWhite,
    [styles.gray]: props.isGray,
    [styles.withoutBorder]: props.withoutBorder,
    [styles.text]: props.isText,
  });

  const { as = { tag: 'button' }, onClick, isDisabled, isLoading } = props;

  const ButtonComponent: React.FC = ({ children }) => {
    switch (as.tag) {
      case 'a':
        return (
          <a {...as} onClick={onClick} className={classes} role='presentation'>
            {children}
          </a>
        );

      case 'button':
        return (
          <button
            {...as}
            onClick={onClick}
            className={classes}
            disabled={isDisabled || isLoading}
          >
            {children}
          </button>
        );

      default:
        return (
          <Link {...as} onClick={onClick} className={classes}>
            {children}
          </Link>
        );
    }
  };

  const {
    iconPosition,
    hasError,
    isSecondary,
    isGray,
    isTransparent,
    children,
  } = props;

  return (
    <ButtonComponent>
      {iconPosition !== 'right' ? getIcon(props) : null}

      {props.isLoading && (
        <Spinner
          className={styles.spinner}
          size='small'
          secondary={isSecondary || isGray || isTransparent}
        />
      )}

      <span className={styles.text}>
        {hasError && !isLoading ? DEFAULT_ERROR_MESSAGE : children}
      </span>

      {iconPosition === 'right' ? getIcon(props) : null}
    </ButtonComponent>
  );
};

export default Button;
