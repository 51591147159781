import { DRIFT_KEY_CAREERS } from '~/constants';
import { BrowserService } from '~/services/Browser';

import {
  isContainerPresent,
  removeOldScript,
  renderScript,
  SCRIPT_LOAD_METHOD,
} from './script';

export enum ChatType {
  HUBSPOT,
  CAREERS,
}

export const DRIFT_CHAT_KEY = DRIFT_KEY_CAREERS;

class ChatService {
  current: ChatType = ChatType.HUBSPOT;
  driftApi: DriftApi | null = null;
  hubspotApi: HubspotApi | null = null;
  hubspotLoadTimeout: null | number = null;

  constructor() {
    if (BrowserService.isBrowser) {
      this.attachEvents();
    }
  }

  setType(newType: ChatType) {
    this.current = newType;
  }

  attachEvents() {
    if (BrowserService.isBrowser && window.drift) {
      window.drift.on('ready', this.handleDriftChatReady);
    }

    if (BrowserService.isBrowser) {
      window.hsConversationsOnReady = [this.handleHubspotChatReady];
    }
  }

  detachEvents() {
    if (BrowserService.isBrowser && window.drift) {
      window.drift.off('ready');
    }
  }

  dispose() {
    if (BrowserService.isBrowser) {
      if (isContainerPresent() && window.drift) {
        this.detachEvents();

        window.drift.unload();
        window.drift = null;
        window.driftt = null;
        this.driftApi = null;

        removeOldScript();
      }

      if (this.hubspotApi) {
        this.hubspotApi.remove();
      }

      if (this.hubspotLoadTimeout) {
        clearTimeout(this.hubspotLoadTimeout);
      }
    }
  }

  loadHubspot() {
    if (BrowserService.isBrowser) {
      if (this.hubspotApi) {
        this.hubspotApi.load();
      } else {
        this.hubspotLoadTimeout = window.setTimeout(
          () => this.loadHubspot(),
          2000,
        );
      }
    }
  }

  renderScript(newType: ChatType) {
    if (this.current !== newType) {
      this.dispose();
      this.setType(newType);
    }

    if (this.current === ChatType.HUBSPOT) {
      return this.loadHubspot();
    } else if (BrowserService.isBrowser) {
      window[SCRIPT_LOAD_METHOD] = this.handleScriptLoad;

      if (DRIFT_KEY_CAREERS) {
        return renderScript(DRIFT_KEY_CAREERS);
      }
    }

    return null;
  }

  open = () => {
    if (this.current === ChatType.CAREERS) {
      if (this.driftApi) {
        this.driftApi.openChat();
      }
    } else if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.open();
    }
  };

  handleScriptLoad = () => {
    this.attachEvents();
  };

  handleDriftChatReady = (api: DriftApi) => {
    this.driftApi = api;
  };

  handleHubspotChatReady = () => {
    this.hubspotApi = window.HubSpotConversations.widget;
  };
}

const instance = new ChatService();
export { instance as ChatService };
