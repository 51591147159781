import React, { useCallback } from 'react';
import classNames from 'classnames';

import { ReactComponent as DribbbleIcon } from '~/assets/icons-social-media/dribbble.svg';
import { ReactComponent as FacebookIcon } from '~/assets/icons-social-media/facebook.svg';
import { ReactComponent as InstagramIcon } from '~/assets/icons-social-media/instagram.svg';
import { ReactComponent as LinkedInIcon } from '~/assets/icons-social-media/linkedin.svg';
import { ReactComponent as TwitterIcon } from '~/assets/icons-social-media/twitter.svg';

import styles from './Socials.module.scss';
import { AnalyticsService } from '~/services/Analytics';

export interface Props {
  className?: string;
  white?: boolean;
  linkClass?: string;
}

export interface IconProps {
  className?: string;
}

export interface Social {
  href: string;
  icon: React.ComponentType<IconProps>;
  label: string;
}

const LINKS: Social[] = [
  {
    href: 'https://www.linkedin.com/company/swing-development/',
    label: 'LinkedIn',
    icon: LinkedInIcon,
  },
  {
    href: 'https://twitter.com/swingdevio',
    label: 'Twitter',
    icon: TwitterIcon,
  },
  {
    href: 'https://www.instagram.com/swingdev/',
    label: 'Instagram',
    icon: InstagramIcon,
  },
  {
    href: 'https://dribbble.com/swingdev/',
    label: 'Dribbble',
    icon: DribbbleIcon,
  },
  {
    href: 'https://www.facebook.com/swingdev/',
    label: 'Facebook',
    icon: FacebookIcon,
  },
];

const SocialMedia: React.FC<Props> = (props) => {
  const classes = classNames(styles.root, props.className);
  const linkClasses = classNames(props.linkClass, {
    [styles.white]: props.white,
  });

  const handleIconClick = useCallback((iconLabel: string) => {
    AnalyticsService.track({
      category: 'Footer',
      action: 'Click',
      label: `${iconLabel} Icon`,
    });
  }, []);

  const renderItem = (social: Social, className: string) => {
    const Icon = social.icon;
    const classes = classNames(styles.link, className);

    return (
      <li className={styles.item} key={social.href}>
        <a
          className={classes}
          href={social.href}
          title={social.label}
          role='button'
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => handleIconClick(social.label)}
        >
          <Icon className={styles.icon} />
        </a>
      </li>
    );
  };

  return (
    <ul className={classes}>
      {LINKS.map((link) => renderItem(link, linkClasses))}
    </ul>
  );
};

export default SocialMedia;
