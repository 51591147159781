import React from 'react';
import classNames from 'classnames';

import styles from './Image.module.scss';
import { Breakpoint } from '~/constants';

type Props = {
  img: string;
  imgMobile?: string;
  alt: string;
  className: string;
  pictureClassName?: string;
};

const Image: React.FC<Props> = ({
  img,
  alt,
  className,
  pictureClassName,
  imgMobile,
}) => {
  const imgClassNames = classNames(styles.img, className);

  if (imgMobile) {
    return (
      <picture className={pictureClassName}>
        <source
          srcSet={imgMobile}
          media={`(max-width: ${Breakpoint.MEDIUM}px)`}
        />
        <img src={img} alt={alt} className={imgClassNames} />
      </picture>
    );
  }

  return <img src={img} alt={alt} className={imgClassNames} />;
};

export default Image;
