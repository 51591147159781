import React from 'react';
import { Link } from 'gatsby';

import FooterEntry from '../FooterEntry';
import EUProjectLogos from '~/components/EUProjectLogos';

import routes from '~/routes';

import styles from './Footer.module.scss';

type Props = {
  inverted?: boolean;
  euSection?: boolean;
};

const CURRENT_YEAR = new Date().getFullYear();

const Footer: React.FC<Props> = ({ inverted = false, euSection = false }) => (
  <footer role='contentinfo'>
    <FooterEntry inverted={inverted} />

    {euSection ? (
      <Link to={routes.EU}>
        <div className={styles.middle}>
          <EUProjectLogos />
        </div>
      </Link>
    ) : (
      <hr className={styles.line} />
    )}

    <div className={styles.lower}>
      <div className={styles.container}>
        <small className={styles.disclaimer}>
          Copyright © {CURRENT_YEAR} Swing Development sp. z o. o., ul. Nowy
          Świat 35 lok. 6a /00-029/ Warszawa, NIP 1070029191, REGON 147123958,
          wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy
          dla m. st. Warszawy w Warszawie, XII Wydział Gospodarczy Krajowego
          Rejestru Sądowego pod numerem KRS 0000499540 kap. zał. 6000 PLN w
          całości opłacony.
        </small>
      </div>
    </div>
  </footer>
);

export default Footer;
