import { ROOT_NODE } from '~/constants';
import Scroll from './Scroll';
import Resize from './Resize';

class BrowserService {
  public readonly rootNode: HTMLElement | null = this.isBrowser
    ? document.getElementById(ROOT_NODE)
    : null;
  public readonly resize: Resize = new Resize(this.isBrowser);
  public readonly scroll: Scroll = new Scroll();

  get isBrowser(): boolean {
    const hasDocument = typeof document === 'object';
    const hasWindow = typeof window === 'object';

    return hasDocument && hasWindow;
  }

  getWindowSize() {
    return this.isBrowser ? window.innerWidth : 0;
  }
}

const instance = new BrowserService();
export { instance as BrowserService };
