import React, { useCallback } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import { AnalyticsService, EventCategory } from '~/services/Analytics';
import { LinkItem } from '~/types';

import styles from './NavigationLinks.module.scss';

export interface Props {
  className?: string;
  linkClassName?: string;
  itemClassName?: string;
  activeClassName?: string;
  onItemClick?: () => void;
  links: LinkItem[];
  analyticsCategory: EventCategory;
}

interface LinkProps extends Omit<Props, 'className' | 'links'> {
  link: LinkItem;
}

const NavigationLink: React.FC<LinkProps> = ({
  itemClassName,
  link,
  linkClassName,
  onItemClick,
  activeClassName,
  analyticsCategory,
}) => {
  const onClick = useCallback(() => {
    AnalyticsService.track({
      category: analyticsCategory,
      action: 'Click',
      label: link.label,
    });

    onItemClick && onItemClick();
  }, [analyticsCategory, onItemClick, link.label]);

  return (
    <li className={classNames(styles.item, itemClassName)} key={link.route}>
      <Link
        className={classNames(styles.link, linkClassName)}
        to={link.route}
        title={link.label}
        aria-label={link.label}
        onClick={onClick}
        activeClassName={activeClassName}
      >
        {link.label}
      </Link>
    </li>
  );
};

const NavigationLinks: React.FC<Props> = ({
  className,
  children,
  links,
  ...props
}) => {
  return (
    <ul className={classNames(styles.list, className)}>
      {links.map((link) => (
        <NavigationLink key={link.label} link={link} {...props} />
      ))}
      {children}
    </ul>
  );
};

export default NavigationLinks;
