import { easing, tween } from 'popmotion';
import styler from 'stylefire';

type ScrollToOptions = Partial<{
  duration: number;
  ease: easing.Easing;
  offset: number;
}>;

class Scroll {
  scrollTo(target: HTMLElement, options?: ScrollToOptions) {
    const { duration, ease, offset = 0 } = options || {};
    const viewportStyler = styler(window);

    tween({
      to: target.offsetTop - offset,
      from: viewportStyler.get('scrollTop'),
      duration: duration || 1000,
      ease: ease || easing.easeInOut,
    }).start((value: number) => {
      viewportStyler.set('scrollTop', value);
    });
  }
}

export default Scroll;
