import React from 'react';
import { Link } from 'gatsby';
import Sticky from 'react-stickynode';
import classNames from 'classnames';

import { LINKS } from '~/constants';
import BurgerButton from '~/components/BurgerButton';
import routes from '~/routes';

import NavigationLinks from '../NavigationLinks';
import Socials from '../SocialMedia';

import { ReactComponent as LogoBig } from './assets/logo-big.svg';
import styles from './Navigation.module.scss';

interface Props {
  className?: string;
  inverted?: boolean;
}

interface State {
  showMobileMenu: boolean;
  stickyActive: boolean;
}

class Navigation extends React.Component<Props, State> {
  state: State = {
    showMobileMenu: false,
    stickyActive: false,
  };

  handleStickyStateChange = (state: Sticky.Status) => {
    this.setState({
      stickyActive: state.status === Sticky.STATUS_FIXED,
    });
  };

  handleItemClick = () => {
    this.setState({
      showMobileMenu: false,
    });
  };

  toggleShowMobileMenu = () => {
    this.setState((prevState: State) => ({
      showMobileMenu: !prevState.showMobileMenu,
    }));
  };
  render() {
    const { className, inverted } = this.props;
    const { showMobileMenu, stickyActive } = this.state;

    const navbarClasses = classNames(styles.navbar, className, {
      [styles.inverted]: inverted,
      [styles.open]: showMobileMenu,
    });

    return (
      <header className={styles.root}>
        <Sticky
          activeClass={styles.active}
          onStateChange={this.handleStickyStateChange}
          innerZ={3}
        >
          <nav className={navbarClasses} role='navigation'>
            <div className={styles.container}>
              <Link to={routes.ROOT} className={styles.logoLink}>
                <LogoBig className={styles.logo} />
              </Link>

              <BurgerButton
                onClick={this.toggleShowMobileMenu}
                active={showMobileMenu}
                className={styles.burgerButton}
                inverted={inverted || stickyActive}
              />

              <div className={styles.wrapper}>
                <NavigationLinks
                  className={styles.list}
                  itemClassName={styles.item}
                  linkClassName={styles.link}
                  onItemClick={this.handleItemClick}
                  activeClassName={styles.selected}
                  links={LINKS}
                  analyticsCategory='Navigation'
                />

                <footer className={styles.footer}>
                  <Socials
                    className={styles.socials}
                    linkClass={styles.socialLink}
                  />
                </footer>
              </div>
            </div>
          </nav>
        </Sticky>
      </header>
    );
  }
}

export default Navigation;
