import React from 'react';
import { Helmet } from 'react-helmet';

import { META } from '~/constants';
import { ChatService, ChatType } from '~/services/Chat';

import CookiePolicyMessage from './CookiePolicyMessage';
import Footer from './Footer';
import Navigation from './Navigation';

import { renderMetas } from './meta';

import 'normalize.css';
import 'rc-slider/assets/index.css';
import 'slick-carousel/slick/slick.css';
import '~/styles/fonts.scss';
import '~/styles/main.scss';

type Props = {
  pageTitle?: string;
  metaDescription?: string;
  invertNavigation?: boolean;
  invertFooter?: boolean;
  footerEUSection?: boolean;
  chatType?: ChatType;
};

const Layout: React.FC<Props> = ({
  children,
  pageTitle,
  metaDescription,
  invertFooter = false,
  invertNavigation = false,
  footerEUSection = false,
  chatType = ChatType.HUBSPOT,
}) => {
  return (
    <>
      <Helmet
        defaultTitle={META.title}
        titleTemplate={META.titleTemplate}
        titleAttributes={{ itemprop: 'name', lang: 'en' }}
        title={pageTitle}
      >
        {renderMetas({ description: metaDescription })}
        {ChatService.renderScript(chatType)}
      </Helmet>
      <Navigation inverted={invertNavigation} />

      <main>{children}</main>

      <Footer inverted={invertFooter} euSection={footerEUSection} />
      <CookiePolicyMessage />
    </>
  );
};

export default Layout;
