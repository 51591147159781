import React from 'react';

import { META, WEBSITE_URL } from '~/constants';

type Props = { description?: string };

export const renderMetas = (props: Props) => {
  const { description } = props;

  return [
    <meta key='charset' charSet='utf-8' />,
    <meta
      key='viewport'
      name='viewport'
      content='width=device-width, initial-scale=1, viewport-fit=cover'
    />,
    <meta
      key='description'
      name='description'
      content={description || META.description}
    />,
    <meta key='keywords' name='keywords' content={META.keywords} />,
    <meta key='name' itemProp='name' content={META.title} />,
    <meta
      key='description'
      itemProp='description'
      content={description || META.description}
    />,
    <meta
      key='image'
      itemProp='image'
      content={`${WEBSITE_URL}/og-cover.png`}
    />,
    <meta key='url' itemProp='url' content={WEBSITE_URL} />,
    <meta
      key='twitter:card'
      name='twitter:card'
      content='summary_large_image'
    />,
    <meta key='twitter:site' name='twitter:site' content='@swingdevio' />,
    <meta key='twitter:title' name='twitter:title' content={META.title} />,
    <meta
      key='twitter:description'
      name='twitter:description'
      content={description || META.description}
    />,
    <meta
      key='twitter:image:src'
      name='twitter:image:src'
      content={`${WEBSITE_URL}/og-cover-twitter.png`}
    />,
    <meta
      key='twitter:image:alt'
      name='twitter:image:alt'
      content={META.imgAlt}
    />,
    <meta key='og:title' property='og:title' content={META.title} />,
    <meta
      key='og:description'
      property='og:description'
      content={description || META.description}
    />,
    <meta key='og:type' property='og:type' content='website' />,
    <meta key='og:url' property='og:url' content={WEBSITE_URL} />,
    <meta
      key='og:image'
      property='og:image'
      content={`${WEBSITE_URL}/og-cover.png`}
    />,
    <meta key='og:site_name' property='og:site_name' content={META.title} />,
    <meta
      key='apple-mobile-web-app-status-bar-style'
      name='apple-mobile-web-app-status-bar-style'
      content='black-translucent'
    />,
    <link key='manifest' rel='manifest' href='/manifest.webmanifest' />,
    <link
      key='mask-icon'
      rel='mask-icon'
      href='/safari-pinned-tab.svg'
      color='#4c4c4c'
    />,
    <meta key='theme-color' name='theme-color' content='#f5f5f5' />,
  ];
};
