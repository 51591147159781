import classNames from 'classnames';
import React, { useCallback } from 'react';

import { CONTACT_EMAIL, LINKS, POLICY_LINKS } from '~/constants';

import { AnalyticsService } from '~/services/Analytics';
import NavigationLinks from '../NavigationLinks';
import SocialMedia from '../SocialMedia';

import styles from './FooterEntry.module.scss';

export interface Props {
  className?: string;
  inverted?: boolean;
}

const FooterEntry: React.FC<Props> = (props) => {
  const classes = classNames(styles.root, props.className, {
    [styles.inverted]: props.inverted,
  });

  const handleEmailClick = useCallback(() => {
    AnalyticsService.track({
      category: 'Footer',
      action: 'Click',
      label: 'Email Address',
    });
  }, []);

  return (
    <div className={classes}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.left}>
            <div className={styles.contact}>
              Say hi, <br />
              <a
                className={styles.mail}
                href={`mailto:${CONTACT_EMAIL}`}
                onClick={handleEmailClick}
              >
                {CONTACT_EMAIL}
              </a>
            </div>

            <SocialMedia className={styles.socials} />
          </div>

          <div className={styles.right}>
            <div className={styles.row}>
              <div className={styles.navigationLinks}>
                <NavigationLinks
                  links={[...LINKS]}
                  analyticsCategory='Footer'
                />
              </div>
              <div className={styles.policyLinks}>
                <NavigationLinks
                  links={POLICY_LINKS}
                  analyticsCategory='Footer'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterEntry;
