import React from 'react';
import classNames from 'classnames';

import styles from './Spinner.module.scss';

export type SpinnerSize = 'large' | 'medium' | 'small';

type Props = {
  className?: string;
  secondary?: boolean;
  size?: SpinnerSize;
};

const Spinner: React.FC<Props> = ({
  className,
  secondary,
  size = 'medium',
}) => {
  const classes = classNames(styles.root, className, {
    [styles[size]]: size,
    [styles.secondary]: secondary,
  });

  return <div className={classes} />;
};

export default Spinner;
