import { BrowserService } from '~/services/Browser';

export type EventCategory =
  | 'Buttons'
  | 'Videos'
  | 'Estimate Project Button'
  | 'Estimate Project Form'
  | 'Navigation'
  | 'Footer'
  | 'Homepage'
  | 'Careers'
  | 'Resources';

interface TrackParameters {
  category?: EventCategory; // Segment uses the "All" fallback when category isn't provided
  action: string;
  label?: string;
  value?: string;
}

class AnalyticsService {
  LOGS_STYLE = {
    accent: 'color: #ff002b;',
    title: 'font-weight: bold;',
    default: 'color: #20282e;',
    value: 'color: #8c0f1a;',
    action: 'color: #3a8441',
  };

  track = ({ category, action, label, value }: TrackParameters) => {
    const params: Omit<TrackParameters, 'action'> = {
      category,
      label,
      value,
    };
    // Filter out undefined properties
    Object.keys(params).forEach((key) => !params[key] && delete params[key]);

    if (BrowserService.isBrowser && window.analytics) {
      window.analytics.track(action, params);
    } else {
      console.group(
        `%cAnalytics %c>> %cTrack`,
        this.LOGS_STYLE.accent,
        this.LOGS_STYLE.default,
        this.LOGS_STYLE.title,
      );
      console.log('%cAction:', this.LOGS_STYLE.action, action);
      console.log('%cParameters:', this.LOGS_STYLE.value, params);
      console.groupEnd();
    }
  };
}

const instance = new AnalyticsService();
export { instance as AnalyticsService };
