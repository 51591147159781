import React, { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

import Image from '~/components/Image';
import FELogo from './assets/logo-fe.svg';
import RPLogo from './assets/logo-rp.svg';
import UELogo from './assets/logo-ue.svg';
import UELogoLeft from './assets/logo-unia-2.svg';
import UMWPLogo from './assets/logo-umwp.svg';

import { Breakpoint } from '~/constants';
import { BrowserService } from '~/services/Browser';

import styles from './EUProjectLogos.module.scss';

interface Props {
  fluid?: boolean;
}

const shouldRenderVertically = () =>
  BrowserService.getWindowSize() < Breakpoint.MEDIUM;

const EUProjectLogos: React.FC<Props> = ({ fluid = false }) => {
  const [renderVertically, setRenderVertically] = useState(
    shouldRenderVertically(),
  );

  const debouncedHandleResize = debounce(() => {
    setRenderVertically(shouldRenderVertically());
  }, 100);

  useEffect(() => {
    BrowserService.resize.addResizeListener(debouncedHandleResize);

    return () =>
      BrowserService.resize.removeResizeListener(debouncedHandleResize);
  }, [debouncedHandleResize]);

  return (
    <div className={fluid ? styles.fluidContainer : styles.container}>
      <Image
        img={FELogo}
        alt='Fundusze Europejskie | Logo'
        className={styles.logo}
      />
      <Image
        img={RPLogo}
        alt='Rzeczpospolita Polska | Logo'
        className={styles.logo}
      />
      <Image
        img={UMWPLogo}
        alt='Urząd Marszałkowski Województwa Pomorskiego | Logo'
        className={styles.logo}
      />
      <Image
        img={renderVertically ? UELogoLeft : UELogo}
        alt='Unia Europejska | Logo'
        className={styles.logo}
      />
    </div>
  );
};

export default EUProjectLogos;
