import PubSub from 'pubsub-js';

type ResizeListenerCallback = (event: WindowEventHandlersEventMap) => void;

const RESIZE_MSG = 'window-resize';

class Resize {
  constructor(isBrowser: boolean) {
    if (!isBrowser) {
      return;
    }

    window.addEventListener('resize', () => {
      PubSub.publish(RESIZE_MSG, event);
    });
  }

  addResizeListener(callback: ResizeListenerCallback) {
    PubSub.subscribe(RESIZE_MSG, callback);
  }

  removeResizeListener(callback: ResizeListenerCallback) {
    PubSub.unsubscribe(callback);
  }
}

export default Resize;
