import React from 'react';

export const SCRIPT_ID = 'driftt-load-script';
export const SCRIPT_INIT_ID = 'driftt-init-script';
export const SCRIPT_LOAD_METHOD = 'driftLoaded';

export const isContainerPresent = () =>
  document.getElementById('drift-widget-container');

export const removeOldScript = () => {
  const script = document.getElementById(SCRIPT_ID);
  const initScript = document.getElementById(SCRIPT_INIT_ID);

  if (script) {
    script.remove();
  }

  if (initScript) {
    initScript.remove();
  }
};

/* tslint:disable max-line-length */
export const renderScript = (apiKey: string) => (
  <script key='drift-script' id={SCRIPT_INIT_ID} async>
    {`"use strict";!function(){var t=window.driftt=window.drift=window.driftt||[];
    if(!t.init){if(t.invoked)return void(window.console&&console.error&&console.error("Drift snippet included twice."));
    t.invoked=!0,t.methods=["identify","config","track","reset","debug","show","ping","page","hide","off","on"],
    t.factory=function(e){return function(){var n=Array.prototype.slice.call(arguments);
    return n.unshift(e),t.push(n),t}},t.methods.forEach(function(e){t[e]=t.factory(e)}),t.load=function(t){
    var e=3e5*Math.ceil(new Date/3e5),n=document.createElement("script");
    n.type="text/javascript",n.async=!0,n.crossorigin="anonymous",n.id="${SCRIPT_ID}",n.src="https://js.driftt.com/include/"+e+"/"+t+".js";
    var i=document.getElementsByTagName("script")[0];i.parentNode.insertBefore(n,i)}}}(),
    drift.SNIPPET_VERSION="0.3.1",drift.load('${apiKey}'),window['${SCRIPT_LOAD_METHOD}']&&window['${SCRIPT_LOAD_METHOD}']();`}
  </script>
);
/* tslint:enable max-line-length */
