import React from 'react';
import { Link } from 'gatsby';
import { ReactCookieProps, withCookies } from 'react-cookie';
import classNames from 'classnames';

import { ReactComponent as CookieIcon } from '~/assets/icons/cookie.svg';
import { AnalyticsService } from '~/services/Analytics';
import Button from '~/components/Button';
import routes from '~/routes';

import styles from './CookiePolicyMessage.module.scss';

type Props = ReactCookieProps;

interface State {
  show: boolean;
}

const TIMEOUT_TIME_MS = 500;

class CookiePolicyMessage extends React.Component<Props, State> {
  shouldShowMessage: boolean = false;

  constructor(props: Props) {
    super(props);
    const { cookies } = this.props;

    if (cookies) {
      this.shouldShowMessage = !cookies.get('cookiesAccepted');
    }

    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    if (this.shouldShowMessage) {
      setTimeout(() => this.setState({ show: true }), TIMEOUT_TIME_MS);
    }
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleClick = () => {
    const { cookies } = this.props;

    if (cookies) {
      cookies.set('cookiesAccepted', true);

      AnalyticsService.track({
        category: 'Buttons',
        action: 'Click',
        label: 'Cookies Accepted Button',
      });
    }

    this.handleClose();
  };

  render() {
    const classes = classNames(styles.root, {
      [styles.show]: this.state.show,
    });

    return (
      <div className={classes}>
        <CookieIcon className={styles.figure} />

        <p className={styles.content}>
          Our site uses cookies to ensure we give you the best experience on our
          website.{' '}
          <Link to={routes.COOKIES_POLICY} className={styles.link}>
            Learn&nbsp;more
          </Link>
        </p>

        <Button onClick={this.handleClick} className={styles.button}>
          GOT IT!
        </Button>
      </div>
    );
  }
}

export default withCookies(CookiePolicyMessage);
